import React from "react";
import { SEO, MarkdownContent, Image } from "@bluefin/components";
import { Grid, Segment } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
} from "../utils/utils";

export default class SalontouragePage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          title={fishermanBusinessWebsitePage.seoTitle}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
          ogUrl={"https://www.bobsteele.com/salontourage/"}
        />
        <Grid
          className={"custom-page-salontourage custom-page-grid"}
          style={{}}
          stackable={true}
        >
          <Grid.Row
            className={"afppfybxkmrfczrm"}
            style={{ padding: 16 }}
            textAlign={"center"}
            verticalAlign={"middle"}
          >
            <Grid.Column
              className={"abeonlmrvhzzlofi"}
              style={{ padding: 16 }}
              width={12}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "6c65d005-2a25-499a-8e16-830eb44cf1e4",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={"ccqftnoflglwcupb"}
            style={{ padding: 16, background: "#f7f7f7" }}
            textAlign={"center"}
            verticalAlign={"middle"}
          >
            <Grid.Column
              className={"smtmarvedwvtfwlg"}
              style={{ padding: 16 }}
              width={12}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "e8bf49a8-38a5-4b1a-b0ac-75874b2d5555",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={"antjysgbaaworpba"}
            style={{ padding: 16 }}
            textAlign={"center"}
          >
            <Grid.Column
              className={"savmtaaondujlnoz"}
              style={{ padding: 16 }}
              width={5}
              textAlign={"right"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Image
                  className={"image-block"}
                  style={{
                    maxHeight: "400px",
                    minHeight: "240px",
                    objectFit: "contain",
                  }}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Image",
                    componentIdentifier: "fc360337-3565-44e0-84de-1e1cf3097434",
                    numToSelect: 1,
                  })}
                  background={false}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column
              className={"zprufmrfrulgxyiy"}
              style={{ padding: 16 }}
              width={8}
              textAlign={"left"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "da06f531-0f56-455a-9425-b709180950e4",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={"wfcludodzdvlfotf"}
            style={{ padding: 16, background: "#f7f7f7" }}
            textAlign={"center"}
          >
            <Grid.Column
              className={"vtxtsuzexhvoxuhv"}
              style={{ padding: 16 }}
              width={2}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <div className={""} style={{}} />
              </Segment>
            </Grid.Column>
            <Grid.Column
              className={"tlnwaipubplquexu"}
              style={{ padding: 16 }}
              width={6}
              textAlign={"left"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "0e24036e-3e7c-4067-8c9e-0a5bd795c442",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column
              className={"oohnagfmclyrnfbv"}
              style={{ padding: 16 }}
              width={5}
              textAlign={"left"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Image
                  className={"image-block"}
                  style={{
                    maxHeight: "400px",
                    minHeight: "240px",
                    objectFit: "contain",
                  }}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Image",
                    componentIdentifier: "b79a4365-0e64-4d4c-9c46-cabe4a476b6f",
                    numToSelect: 1,
                  })}
                  background={false}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column
              className={"aqqurhnjkkhtisad"}
              style={{ padding: 16 }}
              width={2}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <div className={""} style={{}} />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={"utmpsblvdkzmoxxd"}
            style={{ padding: 16 }}
            textAlign={"center"}
            verticalAlign={"middle"}
          >
            <Grid.Column
              className={"jljbtlwzgatarhyf"}
              style={{ padding: 16 }}
              width={12}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "c65b37ab-0133-4917-b075-a77c293fc77b",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={"ibbofvzztbmkqfba"}
            style={{ padding: 16 }}
            textAlign={"center"}
          >
            <Grid.Column
              className={"etreafmladmbfcvh"}
              style={{ padding: 16 }}
              width={3}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Image
                  className={"image-block"}
                  style={{ maxHeight: "300px", objectFit: "contain" }}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Image",
                    componentIdentifier: "d102b617-c854-4f5a-b12a-3caec8e6e91b",
                    numToSelect: 1,
                  })}
                  background={false}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column
              className={"hizwinwwoqvwvxdx"}
              style={{ padding: 16 }}
              width={3}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Image
                  className={"image-block"}
                  style={{ maxHeight: "300px", objectFit: "contain" }}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Image",
                    componentIdentifier: "92c48bf7-f8c7-4e12-b9e0-d24b177b4019",
                    numToSelect: 1,
                  })}
                  background={false}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column
              className={"vhrfckeulmjgvdbt"}
              style={{ padding: 16 }}
              width={3}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Image
                  className={"image-block"}
                  style={{ maxHeight: "300px", objectFit: "contain" }}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Image",
                    componentIdentifier: "550a84b6-d3ba-4842-8653-950a7b76822b",
                    numToSelect: 1,
                  })}
                  background={false}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column
              className={"daufmyxvqomrrckq"}
              style={{ padding: 16 }}
              width={3}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Image
                  className={"image-block"}
                  style={{ maxHeight: "300px", objectFit: "contain" }}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Image",
                    componentIdentifier: "fbdfa7fe-64d3-4299-b6fb-90f4f113976c",
                    numToSelect: 1,
                  })}
                  background={false}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column
              className={"qeeblhgmceaurlia"}
              style={{ padding: 16 }}
              width={3}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Image
                  className={"image-block"}
                  style={{ maxHeight: "300px", objectFit: "contain" }}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Image",
                    componentIdentifier: "ae44c876-552e-4aff-98e7-9024f74f6394",
                    numToSelect: 1,
                  })}
                  background={false}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(_id: { eq: 26984 }) {
      title
      seoTitle
      description
      components {
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        order
        fastId
        componentIdentifier
        contentNodes {
          content
        }
      }
    }
  }
`;
